// libraries.
import { Title } from './components/Title';
import {
  MenuActions,
  modalOptions,
  useMenuContext,
} from 'context/menu.context';
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Paid } from './components/Paid';
import { AmountOwed } from './components/AmountOwed';
import { FormTextFloating, UiTips, toCurrency } from '@meniu/ui';
import { useOrderForBilling } from './hooks/order.hook';
import { useForm } from 'react-hook-form';
import { useMutateRegisterPayment } from 'apiClient/api';
import { toast } from 'react-toastify';

export const ModalOrderPaymentsGlobal = () => {
  const { mutate: mutatePayment } = useMutateRegisterPayment();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<any>({});
  const [{ modal }, dispatch] = useMenuContext();

  const amountToPayFromUser = Number(watch('amountPaid')?.replace(',', '.'));

  const {
    id: orderId,
    amountTotalPaid,
    amountOwed,
    currency,
    locale,
    menuId,
  } = useOrderForBilling();

  const formatAmountTotalPaid = toCurrency(amountTotalPaid, locale, currency);
  const formatAmountTotalToPay = toCurrency(amountOwed, locale, currency);

  const isPaymentInCash = watch('paymentType') === 'cash';
  const moneyToReturn = amountToPayFromUser - amountOwed;

  const close = () => dispatch(MenuActions.closeModal());

  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    if (isPaymentInCash && Number(data.amountPaid) > amountOwed) {
      data.amountPaid = amountOwed;
    }

    mutatePayment(
      {
        _id: orderId,
        ...data,
      },
      {
        onSuccess: () => {
          toast.success(t('Pago registrado con éxito'));
          close();
          reset();
        },
      }
    );
  };

  return (
    <Modal
      show={modal.includes(modalOptions['payment-order-global'])}
      centered
      contentClassName="p-1"
      backdropClassName="zi-1055"
      onHide={close}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title orderId={orderId} />
        <Modal.Body className="d-flex flex-column gap-2">
          <Paid amountTotalPaid={formatAmountTotalPaid} />
          <AmountOwed amountTotalToPay={formatAmountTotalToPay} />
          <FloatingLabel label={t('Método de pago')}>
            <Form.Select
              placeholder={t('A que categoría pertenece') || ''}
              {...register('paymentType', { required: true })}
            >
              <option disabled value="Selecciona metodo de pago"></option>
              <option value="cash">{t('Efectivo')}</option>
              <option value="debit-card">{t('Tarjeta de débito')}</option>
              <option value="credit-card">{t('Tarjeta de crédito')}</option>
              <option value="transfer">{t('Transferencia')}</option>
            </Form.Select>
            {errors.paymentType && (
              <Form.Text className="text-danger">
                {t('Este campo es requerido')}
              </Form.Text>
            )}
          </FloatingLabel>
          <InputGroup>
            <FormTextFloating
              type="currency"
              placeholder={t('Monto recibido')}
              isInvalid={!!errors.amountPaid}
              error={errors.amountPaid && t('Este campo es requerido')}
              control={control}
              intlConfig={{
                currency: currency,
                locale: locale,
              }}
              {...register('amountPaid', {
                required: true,
              })}
            />
          </InputGroup>
          {isPaymentInCash && moneyToReturn > 0 && (
            <div className="d-flex justify-content-between my-2">
              <p>
                <span className="fw-semibold">{t('Devolver al cliente')}:</span>{' '}
                {toCurrency(moneyToReturn, locale, currency)}
              </p>
              <p>
                <span className="fw-semibold">{t('A cobrar')}:</span>{' '}
                {toCurrency(amountOwed, locale, currency)}
              </p>
            </div>
          )}
          <UiTips
            price={isPaymentInCash ? amountOwed : amountToPayFromUser}
            locale={locale}
            currency={currency}
            onChange={(values) => {
              setValue('amountTipPaid', values.tipAmount);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="w-100 fw-bold" type="submit">
            {t('Registrar pago')}
          </Button>
          <Button
            variant="link"
            className="w-100 fw-bold text-dark"
            onClick={close}
          >
            {t('En otro momento')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
