import { useTranslation } from 'react-i18next';
import { PricesByTier } from '../profile/subscriptions/components/PricesByTier';
import { useStripePrices } from 'hooks/useStripePrices';
import { CurrencyConvertor } from '@meniu/ui';
import { Period } from '../profile/subscriptions/components/Period';
import Loading from 'shared/loading.component';
import { Button } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { useAppDispatch, UserActions } from 'context/global.context';
import { modalOptions } from 'context/menu.context';
import { useCreateStripePayment, useProfile } from 'apiClient/api';
import { useLocation, useNavigate } from 'react-router-dom';

export const TrialExpired = () => {
  const { prices, isLoading, setPeriod, period, id } = useStripePrices();

  const location = useLocation();

  const { t } = useTranslation();

  const { data: me = {}, isLoading: isLoadingProfile } = useProfile(undefined, {
    refetchInterval: location.pathname === '/trial-expired' ? 3000 : 10000,
  });

  const { mutate: mutateCreatePayment } = useCreateStripePayment();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate('/');
  };

  const handlePay = (price: string) => {
    if (me.email?.includes('@')) {
      mutateCreatePayment(
        {
          email: me.email || '',
          price,
          stripeAccount: me.stripeId,
        },
        {
          onSuccess: ({ url }) => {
            window.location.href = url;
          },
        }
      );
    } else {
      dispatch(UserActions.setModal(modalOptions.register));
    }
  };

  if (isLoading || isLoadingProfile) return <Loading />;

  return (
    <div className="container d-flex justify-content-center align-items-center h-100 pt-5">
      <div className="card border-0 p-4 flex gap-4 custom-shadow">
        <div className="text-center flex gap-2">
          <h4 className="fw-semibold mb-2">
            {t('Tu prueba de Meniu ha finalizado')}
          </h4>
          <p className="fw-semibold small">
            {t('Compra el Plan Business para seguir usándolo')}
          </p>
        </div>
        <PricesByTier selectedPeriod={period} setPeriod={setPeriod} />

        {prices
          ?.filter(
            (d) =>
              d.recurring?.interval_count === period.period &&
              d.recurring?.interval === period.interval
          )
          .map((d) => (
            <div
              key={d.id}
              className="d-flex flex-column justify-content-center align-items-center gap-1"
            >
              <div className="d-flex flex-column gap-2">
                <div
                  className="d-flex gap-2 align-items-center justify-content-center fw-semibold"
                  style={{ textDecorationLine: 'line-through' }}
                >
                  <CurrencyConvertor
                    valueInMxn={
                      ((d?.unit_amount || 0) + period.discountAmount * 100) /
                      100
                    }
                  />
                </div>
              </div>

              <div className="d-flex flex-column">
                <h1 className="fw-semibold lh-1 mb-0">
                  <CurrencyConvertor valueInMxn={(d?.unit_amount || 0) / 100} />
                  <span className="fs-4">
                    /
                    <Period
                      interval={d.recurring?.interval}
                      interval_count={d.recurring?.interval_count}
                    />
                  </span>
                </h1>
              </div>
              <div>
                {period.discount && (
                  <div className="d-flex align-items-center gap-2 mt-2">
                    <small className="text-success fw-semibold">
                      {t('Ahorra')}{' '}
                      <CurrencyConvertor valueInMxn={period.discountAmount} />{' '}
                      (-{period.discount})
                    </small>
                  </div>
                )}
              </div>
            </div>
          ))}

        <Button
          onClick={() => handlePay(id)}
          variant="dark"
          className="fw-semibold w-100 py-3 rounded-2 btn-animation "
        >
          {t('Comprar ahora')}
        </Button>

        {/* <Button
          hidden={me.trialExpiredNoAccess}
          onClick={goToDashboard}
          variant="outline-dark"
          className="fw-semibold w-100 py-3 rounded-2"
        >
          {t('Volver')}
        </Button> */}
        <div>
          <p className="fw-semibold mt-5 mb-2">{t('Precios por país')}</p>
          <Table hover className="small">
            <thead>
              <tr>
                <th className="ps-0">{t('País')}</th>
                <th>{t('Mensual')}</th>
                <th>{t('6 meses')}</th>
                <th className="pe-0">{t('1 año')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="ps-0">{t('México')}</td>
                <td>$119 MXN</td>
                <td>$649 MXN</td>
                <td className="pe-0">$999 MXN</td>
              </tr>
              <tr>
                <td className="ps-0">{t('Estados Unidos y Latinoamérica')}</td>
                <td>$5.75 USD</td>
                <td>$31.37 USD</td>
                <td className="pe-0">$48.29 USD</td>
              </tr>
              <tr>
                <td className="ps-0">{t('Europa')}</td>
                <td>5.56€ EUR</td>
                <td>30.37€ EUR</td>
                <td className="pe-0">53.77€ EUR</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TrialExpired;
