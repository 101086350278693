export const trialDays = 7;
export const monthPrice = 119;
export const semesterPrice = 649;
export const anualPrice = 999;
export const semesterDiscount = 10;
export const anualDiscount = 30;
export const semesterDiscountAmount = 195;
export const anualDiscountAmount = 689;
export const totalRestaurants = 4000;
export const defaultCountry = 'MX';
